import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Packages from "./pages/Packages/Packages";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./components/Core/NotFound/NotFound";
import ReactGA from "react-ga4";
import TermCondition from "./pages/TermCondition/TermCondition";
import Register from "./pages/Partnership/Register";
import "./services/i18n";
import WishNavigator from "./pages/WishNavigator/WishNavigator";
import store from "./redux/store";
import { Provider } from "react-redux";

// redirect if only hit hostname root
if (window.location.pathname === "/") {
  window.location.replace("/wish");
}

// Initialize Google Analytics in production only
if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_ENV_NAME === "PROD"
) {
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  if (measurementId) {
    ReactGA.initialize(measurementId);
  }
}

function App() {
  const helmetContext = {};

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    // const prefersDarkScheme = window.matchMedia(
    //   "(prefers-color-scheme: dark)"
    // ).matches;

    if (currentTheme === "light") {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    } else if (currentTheme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      // default
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, []);

  return (
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <Router basename="/wish">
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/packages" element={<Packages />} />
              <Route path="/partnership" element={<Register />} />
              <Route path="/terms-conditions" element={<TermCondition />} />
              <Route path="/navigator" element={<WishNavigator />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
