import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ElwynBlackImage from "../../assets/elwyn-black.png";
import ElwynWhiteImage from "../../assets/elwyn-white.png";
import PrimeskillImage from "../../assets/primeskills-logo.png";
import DescOneImage from "../../assets/wish-nav-desc-1.jpg";
import DescTwoImage from "../../assets/wish-nav-desc-2.jpg";
import HeaderImage from "../../assets/wish-nav-header.jpg";
import {
  redirectToTryWishNavigatorWebsite,
  scrollByWithDuration,
} from "../../services/helperService";
import ErrorModal from "../Core/Modal/ErrorModal";
import RegisterNavigatorSuccessModal from "./RegisterNavigatorSuccessModal";
import RegisterWishNavigatorForm from "./RegisterWishNavigatorForm";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const WishNavigatorContent = () => {
  const titleRef = useRef(null);
  const imgHeaderRef = useRef(null);
  const imgDescription1Ref = useRef(null);
  const txtDescription1Ref = useRef(null);
  const imgDescription2Ref = useRef(null);
  const txtDescription2Ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const theme = useSelector((state) => state.theme.theme);

  const scrollToNextSection = () => {
    scrollByWithDuration(0, window.innerHeight - 80, 1000);
  };

  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => {
    document.body.classList.remove("no-scroll");
    setIsErrorModalOpen(false);
  };

  const openSuccessModal = () => setIsSuccessModalOpen(true);
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const onRegisterSuccess = () => {
    openSuccessModal();
  };

  const onRegisterError = (message) => {
    setErrorMessage(message);
    openErrorModal();
  };

  useEffect(() => {
    gsap.fromTo(
      imgHeaderRef.current,
      {
        opacity: 0,
        x: -400,
        scale: 2,
        ease: "power3.out",
      },
      {
        opacity: 1,
        x: 0,
        scale: 1,
        ease: "power3.out",
      }
    );
    gsap.fromTo(
      titleRef.current,
      {
        opacity: 0,
        y: 50,
        ease: "power3.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power3.out",
      }
    );
    gsap.fromTo(
      titleRef.current,
      {
        opacity: 1,
        y: 0,
        ease: "power3.out",
      },
      {
        opacity: 0,
        y: 50,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 5%",
          scrub: 1,
        },
      }
    );
    gsap.fromTo(
      imgDescription1Ref.current,
      { opacity: 0, x: -1000 },
      {
        opacity: 1,
        x: 0,
        duration: 2.0,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgDescription1Ref.current,
          start: "top 90%",
          end: "top 60%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      txtDescription1Ref.current,
      {
        opacity: 0.5,
        y: 120,
      },
      {
        opacity: 1,
        y: 0,
        duration: 2.0,
        ease: "power3.out",
        scrollTrigger: {
          trigger: txtDescription1Ref.current,
          start: "top 90%",
          end: "top 60%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      imgDescription2Ref.current,
      { opacity: 0, x: -600 },
      {
        opacity: 1,
        x: 0,
        duration: 2.0,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgDescription2Ref.current,
          start: "top 90%",
          end: "top 60%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      txtDescription2Ref.current,
      { opacity: 0.5, y: 120 },
      {
        opacity: 1,
        y: 0,
        duration: 2.0,
        ease: "power3.out",
        scrollTrigger: {
          trigger: txtDescription2Ref.current,
          start: "top 90%",
          end: "top 60%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    function preventScroll(event) {
      event.preventDefault();
    }

    if (isSuccessModalOpen || isErrorModalOpen) {
      document.addEventListener("wheel", preventScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", preventScroll);
    }

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("wheel", preventScroll);
    };
  }, [isSuccessModalOpen, isErrorModalOpen]);

  return (
    <div>
      {/* Header section */}
      <section className="relative h-auto md:h-svh text-text dark:text-text-dark bg-surface dark:bg-surface-dark flex flex-col md:flex-row items-center">
        {/* Image Section */}
        <div className="relative w-full md:w-1/2 h-[60svh] md:h-full flex justify-center">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              {/* Loading animation */}
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <img
            ref={imgHeaderRef}
            src={HeaderImage}
            alt="Student using AI agent"
            className="h-full w-full object-cover"
            loading="lazy"
            onLoad={() => setIsLoading(false)}
          />
        </div>

        {/* Text Section */}
        <div
          ref={titleRef}
          className="w-full md:w-1/2 flex flex-col justify-center p-8 md:p-16 "
        >
          <h2 className="text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold">
            {t("nvgrTitle")}
          </h2>
          <p className="text-base 2xl:text-2xl text-text dark:text-gray-300 mt-4 mb-6 2xl:mt-6 2xl:mb-8">
            {t("nvgrSubtitle")}
          </p>
          <button
            onClick={redirectToTryWishNavigatorWebsite}
            className="w-max md:w-1/2 text-base 2xl:text-2xl font-bold bg-primary dark:bg-primary-dark hover:bg-opacity-80 dark:hover:bg-opacity-80 text-white px-6 py-2 2xl:py-4 rounded-full"
          >
            {t("nvgrTryNow")}
          </button>
        </div>

        <button
          onClick={scrollToNextSection}
          className="hidden md:block absolute bg-surface dark:bg-surface-dark rounded-xl p-4 bottom-8 left-[92%] md:left-[50%] transform -translate-x-1/2 text-gray-400 hover:text-gray-200 transition"
          aria-label="Scroll down"
        >
          <ChevronDownIcon className="w-6 h-6" strokeWidth={4} />
        </button>
      </section>

      {/* Register */}
      <RegisterWishNavigatorForm
        onSuccessRegister={onRegisterSuccess}
        onErrorRegister={onRegisterError}
      />

      {/* Description 1 section */}
      <section className="h-auto md:h-[46svh] text-text dark:text-text-dark bg-opacity-70 dark:bg-opacity-70 bg-surfaceSection dark:bg-surfaceSection-dark flex flex-col md:flex-row items-center space-y-8 md:space-y-0">
        {/* Text Section */}
        <div
          ref={txtDescription1Ref}
          className="w-full md:w-[70%] flex flex-col justify-center p-8 md:p-16"
        >
          <h2 className="text-2xl 2xl:text-4xl text-text dark:text-gray-200 mt-4">
            {t("nvgrDesc1Part1")}{" "}
            <span className="font-bold">{t("nvgrDesc1Part2")}</span>{" "}
            {t("nvgrDesc1Part3")}
          </h2>
        </div>

        {/* Image Section */}
        <div
          ref={imgDescription1Ref}
          className="z-10 w-full md:w-[30%] h-full flex justify-center"
        >
          <img
            src={DescOneImage}
            alt="Description wish navigator 1"
            className="h-full w-full object-cover object-[center_left]"
          />
        </div>
      </section>
      {/* Description 2 section */}
      <section className="h-auto md:h-[46svh] text-text dark:text-text-dark bg-surfaceSection dark:bg-surfaceSection-dark flex flex-col md:flex-row items-center space-y-8 md:space-y-0">
        {/* Image Section */}
        <div
          ref={imgDescription2Ref}
          className="z-10 w-full md:w-[30%] h-full flex justify-center"
        >
          <img
            src={DescTwoImage}
            alt="Description wish navigator 1"
            className="h-full w-full object-cover"
          />
        </div>
        {/* Text Section */}
        <div
          ref={txtDescription2Ref}
          className=" w-full md:w-[70%] flex flex-col justify-center p-8 md:p-16"
        >
          <h2 className="text-2xl 2xl:text-4xl text-text text-right dark:text-gray-200 mt-4 mb-8">
            {t("nvgrDesc2Part1")}{" "}
            <span className="font-bold"> {t("nvgrDesc2Part2")}</span>{" "}
            {t("nvgrDesc2Part3")}
          </h2>
        </div>
      </section>
      {/* Collaboration section */}
      <section className="h-auto px-4 md:px-8 py-8 md:py-16 xl:py-32">
        <div className="w-full flex flex-col justify-center items-center text-center p-8 md:p-16">
          <div className="w-2/6 md:w-2/12 flex justify-center items-center space-x-10 mb-12">
            <img
              src={PrimeskillImage}
              alt="Description wish navigator 1"
              className="w-full h-auto object-contain"
            />
            <img
              src={theme === "dark" ? ElwynWhiteImage : ElwynBlackImage}
              alt="Description wish navigator 1"
              className="w-full h-auto object-contain"
            />
          </div>
          <h2 className="text-3xl 2xl:text-5xl font-bold text-primary dark:text-primary-dark mb-6">
            {t("nvgrCollabTitle")}
          </h2>
          <h2 className="text-xl 2xl:text-3xl text-text dark:text-gray-300 mt-4">
            {t("nvgrCollabDesc")}
          </h2>
        </div>
      </section>

      <RegisterNavigatorSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        message={errorMessage}
      />
    </div>
  );
};

export default WishNavigatorContent;
