import { configureStore } from '@reduxjs/toolkit';
import menuReducer from './theme/menuSlice';
import themeReducer from './menu/themeSlice';

const store = configureStore({
  reducer: {
    menu: menuReducer,
    theme: themeReducer,
  },
});

export default store;