import React, { useEffect, useState } from "react";
import CreativeIcon from "../../assets/creative-dimension.svg";
import TechnologyIcon from "../../assets/technology-dimension.svg";
import HealthIcon from "../../assets/health-dimension.svg";
import PeopleIcon from "../../assets/people-dimension.svg";
import NatureIcon from "../../assets/nature-dimension.svg";
import ThingsIcon from "../../assets/things-dimension.svg";
import OrganizationIcon from "../../assets/organization-dimension.svg";
import InfluenceIcon from "../../assets/influence-dimension.svg";
import DimensionModal from "./DimensionModal";
import { useTranslation } from "react-i18next";

const Dimensions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    imgPath: "",
    title: "",
    description: "",
  });
  const { t } = useTranslation();

  const updateModalData = (newData) => {
    setModalData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const openModal = (item) => {
    updateModalData({
      imgPath: item.imgPath,
      title: item.title,
      description: item.description,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const items = [
    {
      title: "Creative Expression",
      imgPath: CreativeIcon,
      description: t("creativeExpressionDesc"),
    },
    {
      title: "Health Science",
      imgPath: HealthIcon,
      description: t("healthScienceDesc"),
    },
    {
      title: "People",
      imgPath: PeopleIcon,
      description: t("peopleDesc"),
    },
    {
      title: "Technology",
      imgPath: TechnologyIcon,
      description: t("technologyDesc"),
    },
    {
      title: "Nature",
      imgPath: NatureIcon,
      description: t("natureDesc"),
    },
    {
      title: "Things",
      imgPath: ThingsIcon,
      description: t("thingsDesc"),
    },
    {
      title: "Organization",
      imgPath: OrganizationIcon,
      description: t("organizationDesc"),
    },
    {
      title: "Influence",
      imgPath: InfluenceIcon,
      description: t("influenceDesc"),
    },
  ];

  useEffect(() => {
    function preventScroll(event) {
      event.preventDefault();
    }

    if (isModalOpen) {
      // document.body.classList.add("no-scroll");
      document.addEventListener("wheel", preventScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", preventScroll);
    }

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("wheel", preventScroll);
    };
  }, [isModalOpen]);

  return (
    <div className="container mx-auto p-4 py-24">
      <h1 className="mb-10 text-2xl sm:text-2xl md:text-4xl lg:text-5xl font-extrabold text-primary dark:text-primary-dark text-center">
        {t("dimensionsTitle")}
      </h1>
      <div className="grid grid-cols-2 p-0 md:p-16 sm:grid-cols-2 md:grid-cols-4 gap-4 gap-y-8 md:gap-y-10">
        {items.map((item, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="text-center text-sm md:text-lg mb-2 md:mb-4 text-text dark:text-text-dark">
              {item.title}
            </div>
            <img
              src={item.imgPath}
              alt={item.title}
              onClick={() => {
                openModal(item);
              }}
              className="w-32 md:w-44 h-auto object-contain"
            />
          </div>
        ))}
      </div>
      <DimensionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={modalData.imgPath}
        title={modalData.title}
        description={modalData.description}
      />
    </div>
  );
};

export default Dimensions;
