import { createSlice } from '@reduxjs/toolkit';

// Get the initial state from localStorage or use a default value
const initialState = {
  theme: localStorage.getItem('theme') || 'light', 
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem('theme', action.payload); // Save to localStorage
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;