import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

function BasePage({
  aboutUsOnClick,
  children,
  solidNavbar = false,
  hasMenu = true,
  aboveImageNavbar = false,
}) {
  const aboutUsOnClicked = aboutUsOnClick;

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar
        aboutUsOnClick={aboutUsOnClicked}
        solid={solidNavbar}
        hasMenu={hasMenu}
        aboveImage={aboveImageNavbar}
      />

      {/* Main Content */}
      <main className="flex-grow bg-background dark:bg-background-dark">
        <div className="mx-auto">{children}</div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default BasePage;
