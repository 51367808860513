import React, { useEffect, useState } from "react";
import DefaultButton from "../Core/Button/DefaultButton";
import axios from "axios";
import FileInput from "../Core/Input/FileInput";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RegisterForm = ({ onErrorRegister, onSuccessRegister }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsapp: "",
    attachment: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    whatsapp: "",
    attachment: "",
    agreement: "",
  });
  const [agreement, setAgreement] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "attachment") {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFormData((prevData) => ({ ...prevData, [name]: reader.result }));
      };
      // Read file as base64
      reader.readAsDataURL(files[0]);
      // set file type
      // eslint-disable-next-line
      setFormData((prevData) => ({ ...prevData, ["mimeType"]: files[0].type }));
      console.log(files[0].type);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleDeleteFile = () => {
    // eslint-disable-next-line
    setFormData((prevData) => ({ ...prevData, ["attachment"]: "" }));
    // eslint-disable-next-line
    setFormData((prevData) => ({ ...prevData, ["mimeType"]: null }));
  };

  // Handle agreement checkbox change
  const handleAgreementChange = (e) => {
    setAgreement(e.target.checked); // Update agreement state
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      name: "",
      email: "",
      whatsapp: "",
      attachment: "",
      agreement: "",
    };
    let valid = true;

    if (!formData.name) {
      newErrors.name = t("ptnrFNameRequired");
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = t("ptnrFEmailRequired");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("ptnrFEmailNotValid");
      valid = false;
    }

    if (!formData.whatsapp) {
      newErrors.whatsapp = t("ptnrFWARequired");
      valid = false;
    } else if (!/^\d+$/.test(formData.whatsapp)) {
      newErrors.whatsapp = t("ptnrFWANotValid");
      valid = false;
    }

    if (!formData.attachment) {
      newErrors.attachment = t("ptnrFFileRequired");
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setErrors({
      name: "",
      email: "",
      whatsapp: "",
      attachment: "",
      agreement: "",
    });

    console.log(agreement);
    if (!agreement) {
      newErrors.agreement = "Mohon centang persetujuan ini";
      setErrors(newErrors);
      return;
    }

    // Post Register
    register(formData);

    // Submit form
    console.log("Form submitted:", formData);
  };

  const termConditionOnClicked = () => {
    navigate("/terms-conditions");
  };

  useEffect(() => {
    // set package id
    // setFormData((prevData) => ({
    //   ...prevData,
    //   // eslint-disable-next-line
    //   ["package"]: selectedPackage.id,
    // }));
  }, []);

  const register = async (formData) => {
    setSubmitLoading(true);
    try {
      const googleSheetHost = process.env.REACT_APP_GOOGLE_SHEET_HOST;
      const response = await axios.post(googleSheetHost, formData, {
        headers: {
          "Content-Type": "text/plain;charse=utf=8",
        },
      });
      if (response.status === 200) {
        if (response.data.code === 400 || response.data.code === 500) {
          onErrorRegister(response.data.message);
        } else {
          onSuccessRegister();
        }
      }
    } catch (error) {
      onErrorRegister(error?.message || "Gagal submit form");
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4 w-full md:w-1/2 ">
      <h1 className="text-lg font-bold text-text dark:text-text-dark mb-8">
        {t("ptnrTitle")}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-text dark:text-text-dark"
            htmlFor="name"
          >
            {t("ptnrFName")}
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="name"
            id="name"
            name="name"
            placeholder={t("ptnrFNamePlaceholder")}
            value={formData.name}
            onChange={handleChange}
            className={`custom-cursor dark:custom-cursor-dark bg-surface dark:bg-surface-dark mt-1 block w-full px-3 py-2 border-2  rounded-lg shadow-sm focus:outline-none focus:ring-primary focus:border-primary focus:dark:border-primary sm:text-sm ${
              errors.name ? "border-red-500 " : "dark:border-gray-500"
            }`}
          />
          {errors.name && (
            <p className="mt-2 text-sm text-red-600">{errors.name}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-text dark:text-text-dark"
            htmlFor="email"
          >
            {t("ptnrFEmail")}
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder={t("ptnrFEmailPlaceholder")}
            value={formData.email}
            onChange={handleChange}
            className={`custom-cursor dark:custom-cursor-dark bg-surface dark:bg-surface-dark mt-1 block w-full px-3 py-2 border-2  rounded-lg shadow-sm focus:outline-none focus:ring-primary focus:border-primary focus:dark:border-primary sm:text-sm ${
              errors.email ? "border-red-500 " : "dark:border-gray-500"
            }`}
          />
          {errors.email && (
            <p className="mt-2 text-sm text-red-600">{errors.email}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-text dark:text-text-dark"
            htmlFor="whatsapp"
          >
            {t("ptnrFWA")}
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="text"
            id="whatsapp"
            name="whatsapp"
            placeholder={t("ptnrFWAPlaceholder")}
            value={formData.whatsapp}
            onChange={handleChange}
            className={`custom-cursor bg-surface dark:bg-surface-dark mt-1 block w-full px-3 py-2 border-2  rounded-lg shadow-sm focus:outline-none focus:ring-primary focus:border-primary focus:dark:border-primary sm:text-sm ${
              errors.whatsapp ? "border-red-500" : "dark:border-gray-500"
            }`}
          />
          {errors.whatsapp && (
            <p className="mt-2 text-sm text-red-600">{errors.whatsapp}</p>
          )}
        </div>

        <div className="mb-6">
          <p className="text-xm font-medium text-text dark:text-text-dark mt-6 mb-1">
            {t("ptnrInstructFileTitle")}
          </p>
          <p className="text-sm font-medium text-text dark:text-text-dark mb-1">
            <strong>{t("ptnrInstructFile1")}</strong> {t("ptnrInstructFile2")}{" "}
            <strong>{t("ptnrInstructFile3")}</strong>
          </p>
          <p className="text-sm font-medium text-text dark:text-text-dark mb-4">
            {t("ptnrInstructFile4")}{" "}
            <span className="italic font-bold">{t("ptnrInstructFile5")}</span>{" "}
            {t("ptnrInstructFile6")}
          </p>
          <label
            className="block text-sm font-medium text-text dark:text-text-dark mb-2"
            htmlFor="receipt"
          >
            {t("ptnrFFile")}
            <span className="text-red-500 ml-1">*</span>
          </label>
          <FileInput
            id="attachment"
            name="attachment"
            accept=".zip"
            onChange={handleChange}
            onDelete={handleDeleteFile}
            errorMessage={errors.attachment}
          />
        </div>

        {/* Agrrement */}
        <div className="mb-4">
          <div className="flex items-start space-x-4">
            <input
              type="checkbox"
              id="agreement"
              for="agreement"
              checked={agreement}
              onChange={handleAgreementChange}
              className="w-6 h-6 accent-primary cursor-pointer"
            />
            <label className="text-sm text-text dark:text-text-dark">
              {t("ptnrFFileTnc1")}{" "}
              <span
                onClick={termConditionOnClicked}
                class="text-primary dark:text-primary-dark hover:underline cursor-pointer"
              >
                {t("ptnrFFileTnc2")}
              </span>{" "}
              {t("ptnrFFileTnc3")}
            </label>
          </div>
          {errors.agreement && (
            <p className="mt-2 text-sm text-red-600">{errors.agreement}</p>
          )}
        </div>

        <DefaultButton
          label={t("ptnrRegister")}
          width="w-full flex items-center justify-center"
          mt="mt-8"
          loading={submitLoading}
          disabled={submitLoading}
        />
      </form>
    </div>
  );
};

export default RegisterForm;
