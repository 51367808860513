import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const languageOptions = {
  en: "English",
  id: "Bahasa",
};

function LanguageSwitcher({ iconColor = "text-gray-800 dark:text-gray-200" }) {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="relative inline-block text-left">
      {/* Selected language code */}
      <button
        onClick={toggleDropdown}
        className={`focus:outline-none w-12 p-3  font-semibold rounded-full md:bg-gray-200 md:dark:bg-gray-800
          ${iconColor}
          `}
      >
        {i18n.language.toUpperCase()}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute -left-12 mt-2 w-40 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg z-10">
          {Object.keys(languageOptions).map((lng) => (
            <button
              key={lng}
              onClick={() => changeLanguage(lng)}
              className={`flex items-center justify-between w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none
                ${
                  i18n.language === lng
                    ? "text-primary font-semibold"
                    : "text-gray-700 dark:text-gray-200"
                }
                `}
            >
              {/* Language label */}
              <span>{languageOptions[lng]}</span>
              {/* Language code */}
              <span className="font-semibold mr-2 uppercase">{lng}</span>{" "}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
