export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(amount);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const redirectTo = (url) => {
  window.location.href = url;
};

export const redirectToWA = () => {
  const waLink = process.env.REACT_APP_WA_LINK;
  window.open(`${waLink}`, "_blank", "noopener,noreferrer");
};

export const redirectToLinkedIn = () => {
  const linkedInUrl = process.env.REACT_APP_LINKEDIN_LINK;
  window.open(`${linkedInUrl}`, "_blank", "noopener,noreferrer");
};

export const redirectToEmail = ({ subject = "" }) => {
  const emailAddress = process.env.REACT_APP_DEFAULT_EMAIL;
  const body = "Halo, saya mau menanyakan...";

  window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
};

export const redirectToDayalimaWebsite = () => {
  const url = process.env.REACT_APP_DAYALIMA_WEB_LINK;
  window.open(`${url}`, "_blank", "noopener,noreferrer");
};

export const redirectToDDIWebsite = () => {
  const url = process.env.REACT_APP_DDI_WEB_LINK;
  window.open(`${url}`, "_blank", "noopener,noreferrer");
};

export const redirectToOdysseyWebsite = () => {
  const url = process.env.REACT_APP_ODYSSEY_WEB_LINK;
  window.open(`${url}`, "_blank", "noopener,noreferrer");
};

export const redirectToTryWishNavigatorWebsite = () => {
  const url = process.env.REACT_APP_ELWYN_WEB_LINK;
  window.open(`${url}`, "_blank", "noopener,noreferrer");
};

export function scrollByWithDuration(x, y, duration) {
  const start = window.scrollY;
  const end = start + y;
  const startTime = performance.now();

  function scrollStep(currentTime) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1); // Progress between 0 and 1
    const ease = easeInOutQuad(progress); // Apply easing function
    const newPosition = start + (end - start) * ease;

    window.scrollTo(0, newPosition);

    if (progress < 1) {
      requestAnimationFrame(scrollStep);
    }
  }

  // Easing function for smoother animation
  function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

  requestAnimationFrame(scrollStep);
}