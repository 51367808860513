import React, { useEffect } from "react";
import BasePage from "../../components/Core/Base/BasePage";
import { useLocation, useNavigate } from "react-router-dom";
import SEO from "../../components/Core/Base/SEO";
import TncRenderer from "../../components/TermCondition/TncRenderer";
import { useTranslation } from "react-i18next";

function TermCondition() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams({ about: true }).toString();
  const {t} = useTranslation()

  const goToAbout = () => {
    navigate(`/?${queryParams}`);
  };

  useEffect(() => {
    // Scroll to top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={t("tncSeoTitle")}
        description={t("tncSeoDescription")}
        keywords="WISH, alat test minat, minat, jurusan, pekerjaan"
        name="Daya Dimensi Indonesia"
        type="article"
        url={`${window.location.origin}${location.pathname}`}
      />
      <BasePage aboutUsOnClick={goToAbout} solidNavbar={true}>
        {/* <TermConditionContent /> */}
        <TncRenderer />
      </BasePage>
    </>
  );
}

export default TermCondition;
