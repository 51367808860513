import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DefaultButton from "../Core/Button/DefaultButton";
import ImageTop from "../../assets/bg-top.svg";
import ImageBot from "../../assets/bg-bot.svg";
import { useNavigate } from "react-router-dom";
import { redirectToWA } from "../../services/helperService";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

function Title() {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const imageTopRef = useRef(null);
  const imageBotRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    gsap.fromTo(
      sectionRef.current,
      {
        opacity: 0,
        y: 100,
        ease: "power3.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power3.out",
      }
    );

    gsap.fromTo(
      sectionRef.current,
      {
        opacity: 1,
        y: 0,
        ease: "power3.out",
      },
      {
        opacity: 0,
        y: 100,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 20%",
          scrub: 1,
        },
      }
    );

    gsap.fromTo(
      imageTopRef.current,
      {
        scale: 1,
        opacity: 1,
        ease: "power3.out",
      },
      {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imageTopRef.current,
          start: "top 4%",
          scrub: 1,
        },
      }
    );

    gsap.fromTo(
      imageBotRef.current,
      {
        scale: 1,
        opacity: 1,
        ease: "power3.out",
      },
      {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imageBotRef.current,
          start: "top 80%",
          scrub: 1,
        },
      }
    );
  }, []);

  const onRegisterNowClick = () => {
    navigate("/packages");
  };

  const onContactNowClick = () => {
    redirectToWA();
  };

  return (
    <div className="bg-background dark:bg-background-dark">
      <div className="relative h-svh w-full">
        <img
          ref={imageTopRef}
          src={ImageTop}
          alt="bg-top"
          className="absolute top-20 md:top-24 left-4 md:left-4 lg:left-8 h-auto w-2/5 md:w-1/5 bg-no-repeat"
        />

        <img
          ref={imageBotRef}
          src={ImageBot}
          alt="bg-bottom"
          className="absolute bottom-8 right-4 md:right-4 lg:right-8 h-auto w-2/5 md:w-1/5 bg-no-repeat"
        />

        <div className="relative z-10 flex items-center justify-center h-full">
          <div className="container mx-auto px-4 py-8">
            <section
              ref={sectionRef}
              className="flex items-center justify-center"
            >
              <div className="container text-center">
                <h1 className="text-2xl md:text-4xl 2xl:text-5xl font-extrabold leading-tight text-text-light dark:text-text-dark">
                  {t("title1")}{" "}
                  <span className="text-primary dark:text-primary-dark">
                    {t("title2")}
                  </span>{" "}
                  {t("title3")}{" "}
                  <span className="text-primary dark:text-primary-dark">
                    {t("title4")}
                  </span>
                </h1>
                <h2 className="mt-1 lg:mt-2 text-2xl md:text-4xl 2xl:text-5xl font-extrabold leading-tight text-text-light dark:text-text-dark">
                  {t("title5")}{" "}
                  <span className="text-primary dark:text-primary-dark">
                    {t("title6")}
                  </span>{" "}
                  {t("title7")}
                </h2>
                <p className="mt-4 xl:px-44  text-center text-sm sm:text-sm md:text-lg 2xl:text-xl text-gray-600 dark:text-gray-300">
                  {t("subtitle1")}{" "}
                  <strong className="dark:text-gray-50">
                    {t("subtitle2")}
                  </strong>
                   {t("subtitle3")}{" "}
                  <strong className="dark:text-gray-50">
                    {t("subtitle4")}
                  </strong>{" "}
                  {t("subtitle5")}{" "}
                  <strong className="dark:text-gray-50">
                    {t("subtitle6")}
                  </strong>
                </p>
                <div className="h-auto mt-8 flex items-center justify-center">
                  <div className="grid grid-cols-1 gap-4 md:gap-6 sm:grid-cols-2 ">
                    <DefaultButton
                      label={t("contactUs")}
                      onClick={onContactNowClick}
                      px="px-8 md:px-10"
                      py="py-2 md:py-2 2xl:py-4"
                      mt="mt-0"
                      textSize="text-lg md:text-xl"
                      textColor="text-primary dark:text-primary-dark"
                      border="border-2"
                      borderColor="border-primary dark:border-primary-dark"
                      hover="hover:bg-gray-200 hover:dark:bg-gray-600 transition duration-300 "
                      bg=""
                      hasLogo={true}
                    />

                    <DefaultButton
                      label={t("getWishToday")}
                      onClick={onRegisterNowClick}
                      px="px-8 md:px-10"
                      py="py-2 md:py-2 2xl:py-4"
                      mt="mt-0"
                      border="border-2"
                      borderColor="border-primary dark:border-primary-dark"
                      textSize="text-lg md:text-xl"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
