import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ReportViewModal = ({ pdfUrl, isOpen, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState(600); // Default width for PDF page
  const modalRef = useRef(null);
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // Set the width of the PDF page to fit the modal width
  useEffect(() => {
    const updateWidth = () => {
      if (modalRef.current) {
        setWidth(modalRef.current.clientWidth); // Set width to match modal's width
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen
          ? "fixed inset-0 z-50 flex items-center justify-center"
          : "hidden"
      }`}
    >
      {/* Overlay Background */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* PDF Modal Content */}
      <div
        ref={modalRef}
        className="relative bg-surface dark:bg-surface-dark w-full max-w-4xl mx-auto rounded-xl shadow-xl overflow-hidden"
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-200 bg-surface dark:bg-surface-dark">
          <h2 className="text-base md:text-lg font-semibold text-text dark:text-text-dark">
            {t("reportPreview")}
          </h2>

          <button
            onClick={onClose}
            className="text-sm font-semibold text-primary dark:text-primary-dark hover:text-red-700"
          >
            <XMarkIcon className="h-auto w-6" />
          </button>
        </div>

        {/* Scrollable PDF Content */}
        <div className="overflow-y-scroll max-h-[85vh]">
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div>
                <div className="p-4 w-full flex justify-center items-center text-base text-text dark:text-text-dark">
                  Mohon tunggu, memuat report...
                </div>
                <div className="flex items-center justify-center mb-6 mt-2">
                  <div className="w-12 h-12 border-4 border-primary-dark border-solid border-t-transparent rounded-full animate-spin"></div>
                </div>
              </div>
            }
            error={
              <div className="p-4 w-full flex justify-center items-center text-base text-text dark:text-text-dark">
                Gagal memuat report!
              </div>
            }
          >
            {/* Render each page with reduced margin */}
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_${index + 1}`} className="pb-2 bg-gray-300">
                {" "}
                {/* Reduce margin between pages */}
                <Page
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={width - 16}
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default ReportViewModal;
