import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from "react";

function ExpansionTile({ title, value }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(isExpanded ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isExpanded]);

  return (
    <div
      className={`my-4 rounded-xl overflow-hidden transition-all duration-300 ease-in-out hover:bg-expansion hover:dark:bg-expansion-dark {
      ${isExpanded ? "bg-expansion dark:bg-expansion-dark shadow-sm" : "bg-background dark:bg-background-dark"}
      }`}
    >
      <div
        onClick={toggleExpansion}
        className={`p-4 cursor-pointer text-text dark:text-text-dark flex items-center  ${
          isExpanded ? "rounded-t-xl" : "rounded-xl"
        }`}
      >
        <div
          className={`mr-2 md:mr-3 transition-transform transform duration-500 ${
            isExpanded ? "rotate-45 hidden" : "rotate-0"
          }`}
        >
          <PlusCircleIcon className="text-xl font-bold w-6 h-6 text-text dark:text-text-dark" />
        </div>
        <div
          className={`mr-2 md:mr-3 transition-transform transform duration-500 ${
            isExpanded ? "rotate-0 " : "rotate-45 hidden"
          }`}
        >
          <MinusCircleIcon className="text-xl font-bold w-6 h-6 text-text dark:text-text-dark" />
        </div>
        <h2 className="text-sm md:text-sm lg:text-lg  font-semibold text-start ">
          {title}
        </h2>
      </div>
      <div
        ref={contentRef}
        className="ml-8 md:ml-9 text-sm md:text-base lg:text-base overflow-hidden text-start text-gray-600 dark:text-gray-300 transition-max-height duration-300 ease-in-out"
        style={{ maxHeight }}
      >
        <div className="px-4 pt-0 pb-4">{value}</div>
      </div>
    </div>
  );
}

export default ExpansionTile;
