import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef } from "react";

const DimensionModal = ({ isOpen, onClose, imageSrc, title, description }) => {
  const modalRef = useRef(null);

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="m-4 p-6 pb-12 bg-surface dark:bg-surface-dark  rounded-3xl shadow-lg max-w-2xl w-full"
      >
        <div className="flex justify-end">
          <button
            className="top-0 right-0 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            onClick={onClose}
          >
            <XMarkIcon className="h-auto w-6" />
          </button>
        </div>
        <div className="flex flex-col md:flex-row gap-6 md:gap-4">
          <div className="flex justify-center md:w-1/4">
            <img
              src={imageSrc}
              alt="Modal Content"
              className="w-36 h-36 object-cover rounded-lg"
            />
          </div>
          <div className="md:w-3/4 items-center">
            <h1 className="mb-2 md:mb-4 text-text dark:text-text-dark text-lg md:text-xl font-bold">
              {title}
            </h1>
            <p className="text-text dark:text-text-dark max-h-48 md:max-h-64 overflow-y-auto">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DimensionModal;
