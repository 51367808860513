import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BasePage from "../../components/Core/Base/BasePage";
import SEO from "../../components/Core/Base/SEO";
import About from "../../components/Home/About";
import Dimensions from "../../components/Home/Dimensions";
import Faq from "../../components/Home/Faq";
import Promotion from "../../components/Home/Promotion";
import ReportViewModal from "../../components/Home/ReportViewModal";
import Title from "../../components/Home/Title";

function Home() {
  const aboutRef = useRef(null);
  const faqRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const autoScrollAbout = queryParams.get("about") || false;
  const autoScrollFaq = queryParams.get("faq")?.length > 0 || false;
  const faqTab = queryParams.get("faq") || "about-wish";
  const [isReportOpen, setIsReportOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const reportId = "/preview-report-wish-id.pdf";
  const reportEn = "/preview-report-wish-en.pdf";

  const openReportModal = () => {
    setIsReportOpen(true);
  };

  const closeReportModal = () => {
    setIsReportOpen(false);
  };

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const goToAbout = () => {
    scrollToSection(aboutRef);
  };

  useEffect(() => {
    if (autoScrollAbout) {
      scrollToSection(aboutRef);
    }
    if (autoScrollFaq) {
      scrollToSection(faqRef);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SEO
        title={t("homeSeoTitle")}
        description={t("homeSeoDescription")}
        keywords="WISH, alat test minat, minat, jurusan, pekerjaan"
        name="Daya Dimensi Indonesia"
        type="article"
        url={`${window.location.origin}${location.pathname}`}
      />
      <BasePage aboutUsOnClick={goToAbout}>
        <Title />
        <About ref={aboutRef} onOpenReportPreview={openReportModal} />
        <Dimensions />
        <Faq ref={faqRef} defaultTab={faqTab} />
        <Promotion />

        <ReportViewModal
          pdfUrl={i18n.language === "en" ? reportEn : reportId}
          isOpen={isReportOpen}
          onClose={closeReportModal}
        />
      </BasePage>
    </>
  );
}

export default Home;
