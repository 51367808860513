import React from "react";
import Logo from "../../../assets/logo.svg";
import { ReactComponent as OdysseyLogo } from "../../../assets/odyssey-logo.svg";
import DayalimaLogo from "../../../assets/dayalima-logo.svg";
import DDILogo from "../../../assets/ddi-logo.svg";
import EmailIcon from "../../../assets/mail.svg";
import WhatsappIcon from "../../../assets/wa.svg";
import LinkedinIcon from "../../../assets/linkedin.svg";
import {
  redirectToDayalimaWebsite,
  redirectToDDIWebsite,
  redirectToEmail,
  redirectToLinkedIn,
  redirectToOdysseyWebsite,
  redirectToWA,
} from "../../../services/helperService";
import { useTranslation } from "react-i18next";

function Footer() {
  const d = new Date();
  const { t } = useTranslation();

  const onEmailClick = () => {
    redirectToEmail({ subject: "Pertanyaan Tentang WISH" });
  };

  const onWaClick = () => {
    redirectToWA();
  };

  const onLinkedInClick = () => {
    redirectToLinkedIn();
  };

  const onDayalimaClick = () => {
    redirectToDayalimaWebsite();
  };

  const onDDIClick = () => {
    redirectToDDIWebsite();
  };

  const onOdysseyClick = () => {
    redirectToOdysseyWebsite();
  };

  return (
    <footer className="bg-surface dark:bg-surface-dark text-text dark:text-text-dark">
      <div className="md:border-2 border-gray-200 dark:border-gray-600 md:m-4 md:px-4 py-16 rounded-lg">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-[2fr,1fr,1fr] gap-8">
            {/* Column 1 */}
            <div className="flex flex-col">
              <div className="mb-6 flex">
                <img
                  src={Logo}
                  alt="Logo"
                  className="flex flex-col h-7 w-auto"
                />
                <div className="flex flex-col mx-2">
                  <div className="h-full w-px bg-gray-300 mx-2"></div>
                </div>
                <button onClick={onOdysseyClick}>
                  <OdysseyLogo className="w-auto h-8" />
                </button>
              </div>
              <h3 className="text-base mb-4">{t("supportedBy")}</h3>
              <div className="flex w-3/4 md:w-2/3 bg-white px-6 py-4 rounded-lg">
                <div className="flex flex-col justify-center ">
                  <button onClick={onDayalimaClick}>
                    <img
                      src={DayalimaLogo}
                      alt="D5 Logo"
                      className="h-auto w-44"
                    />
                  </button>
                </div>
                <div className="flex flex-col mx-4 ">
                  <div className="h-full w-px bg-gray-300 mx-2"></div>
                </div>
                <div className="flex flex-col justify-center">
                  <button onClick={onDDIClick}>
                    <img src={DDILogo} alt="DDI Logo" className="h-auto w-44" />
                  </button>
                </div>
              </div>
            </div>

            {/* Column 2 */}
            <div className="flex flex-col ">
              <h3 className="text-lg font-semibold mb-4">{t("address")}</h3>
              <p className="text-sm md:text-sm">
                Kantor Taman E3.3 Unit B 3-3A Kawasan, Jl. Mega Kuningan Barat
                No.RT.1, RT.1/RW.2, Kuningan, Kuningan Tim., Kecamatan
                Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta
                12950
              </p>
            </div>

            {/* Column 3 */}
            <div className="flex flex-col ">
              <h3 className="text-lg font-semibold mb-4">
                {t("contactUsFooter")}
              </h3>

              <div className="flex">
                <div className="flex flex-col rounded-full p-3 bg-white">
                  <button onClick={onEmailClick}>
                    <img src={EmailIcon} alt="email" className="h-auto w-8" />
                  </button>
                </div>
                <div className="flex flex-col rounded-full p-3 mx-2 bg-white">
                  <button onClick={onWaClick}>
                    <img
                      src={WhatsappIcon}
                      alt="whatsapp"
                      className="h-auto w-8"
                    />
                  </button>
                </div>
                <div className="flex flex-col rounded-full p-3 bg-white">
                  <button onClick={onLinkedInClick}>
                    <img
                      src={LinkedinIcon}
                      alt="whatsapp"
                      className="h-auto w-8"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-10">
            <p className="text-sm text-center text-gray-400">
              {t("copyright")} {d.getFullYear()} © Dayalima Abisatya •{" "}
              {t("allRightReserved")}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
