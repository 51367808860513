import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Core/Button/DefaultButton";

const RegisterWishNavigatorForm = ({ onErrorRegister, onSuccessRegister }) => {
  const { t, i18n } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const clearInput = () => {
    setFormData({
      email: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      email: "",
    };
    let valid = true;

    if (!formData.email) {
      newErrors.email = t("ptnrFEmailRequired");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("ptnrFEmailNotValid");
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setErrors({
      email: "",
    });

    // Post Register
    register(formData);

    // Submit form
    console.log("Form submitted:", formData);
  };

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const register = async (formData) => {
    setSubmitLoading(true);
    try {
      const googleSheetHost = process.env.REACT_APP_GOOGLE_SHEET_NAVIGATOR_HOST;
      const response = await axios.post(googleSheetHost, formData, {
        headers: {
          "Content-Type": "text/plain;charse=utf=8",
        },
      });
      if (response.status === 200) {
        if (response.data.code === 400 || response.data.code === 500) {
          onErrorRegister(response.data.message);
        } else {
          onSuccessRegister();
          clearInput();
        }
      }
    } catch (error) {
      onErrorRegister(error?.message || "Gagal submit form");
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="h-auto md:h-[42svh] mx-auto px-4 py-20 w-full bg-surfaceSection dark:bg-surfaceSection-dark">
      <div className="relative flex items-center justify-center h-full">
        <div className="container mx-auto px-4 py-8">
          <h2 className=" text-center text-3xl 2xl:text-5xl font-bold text-primary dark:text-primary-dark mb-6">
            {t("nvgrRegisterTitle")}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <div className="w-full md:w-96">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("nvgrEmail")}
                  value={formData.email}
                  onChange={handleChange}
                  className={`text-center w-full custom-cursor dark:custom-cursor-dark bg-surface dark:bg-surface-dark mt-1 px-3 py-3 border-2 rounded-full shadow-sm focus:outline-none focus:ring-primary focus:border-primary focus:dark:border-primary text-xl  ${
                    errors.email ? "border-red-500 " : "dark:border-gray-500"
                  }`}
                />
                {errors.email && (
                  <p className="mt-2 text-center text-sm text-red-600">
                    {errors.email}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <DefaultButton
                label={t("nvgrSignUp")}
                width="w-max md:w-60 flex items-center justify-center"
                mt="mt-8"
                px="px-6"
                loading={submitLoading}
                disabled={submitLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterWishNavigatorForm;
