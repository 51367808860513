import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BasePage from "../../components/Core/Base/BasePage";
import SEO from "../../components/Core/Base/SEO";
import WishNavigatorContent from "../../components/WishNavigator/WishNavigatorContent";

function WishNavigator() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams({ about: true }).toString();
  const { t } = useTranslation();

  const goToAbout = () => {
    navigate(`/?${queryParams}`);
  };

  useEffect(() => {
    // Scroll to top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={t("nvgrSeoTitle")}
        description={t("nvgrSeoDesc")}
        keywords="WISH, alat test minat, minat, jurusan, pekerjaan, WISH Navigator, AI, Agent"
        name="Daya Dimensi Indonesia"
        type="article"
        url={`${window.location.origin}${location.pathname}`}
      />
      <BasePage
        aboutUsOnClick={goToAbout}
        solidNavbar={false}
        aboveImageNavbar={true}
      >
        <WishNavigatorContent />
      </BasePage>
    </>
  );
}

export default WishNavigator;
