import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/logo.svg";
import { ReactComponent as OdysseyLogo } from "../../../assets/odyssey-logo.svg";
import { setActiveMenu } from "../../../redux/theme/menuSlice";
import { redirectToOdysseyWebsite } from "../../../services/helperService";
import LanguageSwitcher from "../../Language/LanguageSwitcher";
import DefaultButton from "../Button/DefaultButton";
import ThemeToggle from "../Button/ThemeToggle";

function Navbar({
  aboutUsOnClick,
  solid = false,
  hasMenu = true,
  aboveImage = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { t } = useTranslation();
  const hrefRoot = "/wish";
  const hrefPackages = "/wish/packages";
  const hrefPartner = "/wish/partnership";
  const hrefWishNavigator = "/wish/navigator";
  const activeMenu = useSelector((state) => state.menu.activeMenu);
  const dispatch = useDispatch();

  const onOdysseyClick = () => {
    redirectToOdysseyWebsite();
  };

  const handleMenuClick = (menu) => {
    dispatch(setActiveMenu(menu));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 
      ${
        solid
          ? "bg-surface dark:bg-surface-dark shadow-sm"
          : isScrolled
          ? "bg-surface dark:bg-surface-dark shadow-sm"
          : isOpen
          ? "bg-surface dark:bg-surface-dark"
          : "bg-transparent dark:bg-transparent"
      }
    `}
    >
      <div className="container 2xl:max-w-[86%] mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo on the left */}

        <div className="flex items-center ">
          <a
            href={hrefRoot}
            className="flex flex-col text-2xl font-bold text-gray-900 dark:text-white"
            onClick={() => handleMenuClick("")}
          >
            <img src={Logo} alt="Logo" className="h-5 md:h-6 xl:h-7 w-auto" />{" "}
          </a>
          <div className="flex flex-col mx-[0.2rem] lg:mx-2">
            <div className="h-8 w-px bg-gray-300 mx-2"></div>
          </div>

          <button onClick={onOdysseyClick}>
            <OdysseyLogo
              className={`h-7 xl:h-8 w-auto 
              ${
                isScrolled
                  ? "text-text dark:text-white"
                  : isOpen
                  ? "text-gray-800 dark:text-gray-200"
                  : aboveImage
                  ? "dark:text-text"
                  : "dark:text-white"
              }
              `}
            />
          </button>
        </div>

        {/* Menu and Theme Toggle on the right */}
        <div
          className={`items-center
            ${
              hasMenu
                ? "hidden xl:flex lg:hidden md:hidden sm:hidden"
                : "hidden"
            }
          `}
        >
          <a
            href={hrefWishNavigator}
            onClick={() => handleMenuClick("wish-navigator")}
            className={`mr-1 px-3 py-1 relative cursor-pointer text-center text-sm lg:text-base font-semibold k
              ${aboveImage ? "dark:bg-surface-dark dark:rounded-full" : ""}
              ${
                activeMenu === "wish-navigator"
                  ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                  : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
              }
              `}
          >
            {t("wishNavigator")}
            <span className="absolute rotate-flag -top-1 -left-1 p-2 bg-primary-dark bg-opacity-80 text-[0.48rem] text-white font-bold rounded-full h-5 w-5 flex items-center justify-center">
              New
              <span class="-z-10 animate-ping absolute inline-flex h-4 w-4 rounded-full bg-sky-400 opacity-75"></span>
              <span class="-z-10 relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
          </a>

          <p
            onClick={() => {
              handleMenuClick("about-us");
              aboutUsOnClick();
            }}
            className={`mr-1 px-3 py-1 cursor-pointer text-center text-sm lg:text-base font-semibold 
              ${aboveImage ? "dark:bg-surface-dark dark:rounded-full" : ""}
              ${
                activeMenu === "about-us"
                  ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                  : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
              }
              `}
          >
            {t("aboutUs")}
          </p>

          <a
            href={hrefPackages}
            onClick={() => handleMenuClick("packages")}
            className={` px-3 py-1 cursor-pointer text-center text-sm lg:text-base font-semibold 
               ${aboveImage ? "dark:bg-surface-dark dark:rounded-full" : ""}
               ${
                 activeMenu === "packages"
                   ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                   : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
               }
              `}
          >
            {t("packageOptions")}
          </a>

          <a
            href={hrefPartner}
            className="text-2xl font-bold text-gray-900 dark:text-white mx-4"
          >
            <DefaultButton
              label={t("partnerWithUs")}
              textSize="text-sm lg:text-base"
              textColor="text-primary dark:text-primary-dark"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
              bg="transparent"
              hover="hover:bg-gray-200 hover:dark:bg-gray-800 transition duration-300"
            />
          </a>

          <a
            href={hrefPackages}
            className="text-2xl font-bold text-gray-900 dark:text-white mr-4"
          >
            <DefaultButton
              label={t("registerNow")}
              textSize="text-sm lg:text-base"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
            />
          </a>
          <div className="mr-4">
            <LanguageSwitcher />
          </div>
          <div className="">
            <ThemeToggle />
          </div>
        </div>

        {/* Tablet/Ipad Menu Button */}
        <div
          className={` items-center space-x-3
            ${hasMenu ? "hidden xl:hidden lg:flex md:flex" : "hidden"}
          `}
        >
          <a
            href={hrefPartner}
            className="text-2xl font-bold text-gray-900 dark:text-white"
          >
            <DefaultButton
              label={t("partnerWithUs")}
              textSize="text-sm lg:text-base"
              textColor="text-primary dark:text-primary-dark"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
              bg="transparent"
              hover="hover:text-white hover:dark:text-white hover:bg-cyan-600 hover:dark:bg-cyan-800 transition duration-300"
            />
          </a>

          <a
            href={hrefPackages}
            className="text-2xl font-bold text-gray-900 dark:text-white"
          >
            <DefaultButton
              label={t("registerNow")}
              textSize="text-sm lg:text-base"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
            />
          </a>

          <LanguageSwitcher />
          <ThemeToggle />

          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`text-gray-800 dark:text-gray-200 focus:outline-none transition-transform transform duration-500`}
          >
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <path d="M18 6 L6 18" />
                <path d="M6 6 L18 18" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            )}
            {/*  */}
          </button>
        </div>

        <div
          className={` 
            ${hasMenu ? "hidden" : "flex items-center space-x-4 xl:space-x-6"}
          `}
        >
          <LanguageSwitcher />
          <ThemeToggle />
        </div>

        {/* Mobile Menu Button */}
        <div
          className={` items-center
           ${hasMenu ? "md:hidden flex" : "hidden"}
          `}
        >
          {/* Theme Toggle in Mobile Menu */}
          <div className="flex px-2 items-center">
            <LanguageSwitcher
              iconColor={
                isScrolled
                  ? "text-gray-800 dark:text-gray-200"
                  : isOpen
                  ? "text-gray-800 dark:text-gray-200"
                  : aboveImage
                  ? "text-gray-800"
                  : "text-gray-800 dark:text-gray-200"
              }
            />
            <ThemeToggle
              iconColor={
                isScrolled
                  ? "text-gray-800 dark:text-gray-200"
                  : isOpen
                  ? "text-gray-800 dark:text-gray-200"
                  : aboveImage
                  ? "text-gray-800"
                  : "text-gray-800 dark:text-gray-200"
              }
            />
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={` focus:outline-none transition-transform transform duration-500
              ${
                isScrolled
                  ? "text-gray-800 dark:text-gray-200"
                  : isOpen
                  ? "text-gray-800 dark:text-gray-200"
                  : aboveImage
                  ? "text-gray-800"
                  : "text-gray-800 dark:text-gray-200"
              }
              `}
          >
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <path d="M18 6 L6 18" />
                <path d="M6 6 L18 18" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            )}
            {/*  */}
          </button>
        </div>
      </div>

      {/* Tab/Ipad Menu Content */}
      <div className={`xl:hidden ${isOpen ? "md:block hidden" : "hidden"}`}>
        <div className="py-2 pb-4 bg-surface dark:bg-surface-dark shadow-sm justify-center items-start text-center">
          <div className="mb-1">
            <a
              href={hrefWishNavigator}
              onClick={() => handleMenuClick("wish-navigator")}
              className={`px-3 py-1 relative cursor-pointer text-center text-sm lg:text-base font-semibold k
              ${
                activeMenu === "wish-navigator"
                  ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                  : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
              }
                `}
            >
              {t("wishNavigator")}
              <span className="absolute rotate-flag  -top-1 -left-1 p-2 bg-primary-dark bg-opacity-80 text-[0.48rem] text-white font-bold rounded-full h-5 w-5 flex items-center justify-center">
                New
                <span class="-z-10 animate-ping absolute inline-flex h-4 w-4 rounded-full bg-sky-400 opacity-75"></span>
                <span class="-z-10 relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span>
            </a>
          </div>
          <p
            onClick={() => {
              setIsOpen(!isOpen);
              handleMenuClick("about-us");
              aboutUsOnClick();
            }}
            className={`block px-4 py-2 cursor-pointer font-semibold text-base
               ${
                 activeMenu === "about-us"
                   ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                   : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
               }
              `}
          >
            {t("aboutUs")}
          </p>
          <a
            href={hrefPackages}
            onClick={() => handleMenuClick("packages")}
            className={`block px-4 py-2 cursor-pointer font-semibold text-base 
              ${
                activeMenu === "packages"
                  ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                  : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
              }
              `}
          >
            {t("packageOptions")}
          </a>
        </div>
      </div>

      {/* Mobile Menu Content */}
      <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
        <div className="py-2 pb-4 bg-surface dark:bg-surface-dark shadow-sm justify-center items-start text-center">
          <div className="mb-1">
            <a
              href={hrefWishNavigator}
              onClick={() => handleMenuClick("wish-navigator")}
              className={`px-3 py-1 relative cursor-pointer text-center text-sm lg:text-base font-semibold k
              ${
                activeMenu === "wish-navigator"
                  ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                  : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
              }
                `}
            >
              {t("wishNavigator")}
              <span className="absolute rotate-flag  -top-1 -left-1 p-2 bg-primary-dark bg-opacity-80 text-[0.48rem] text-white font-bold rounded-full h-5 w-5 flex items-center justify-center">
                New
                <span class="-z-10 animate-ping absolute inline-flex h-4 w-4 rounded-full bg-sky-400 opacity-75"></span>
                <span class="-z-10 relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span>
            </a>
          </div>
          <p
            onClick={() => {
              setIsOpen(!isOpen);
              handleMenuClick("about-us");
              aboutUsOnClick();
            }}
            className={`block px-4 py-2 cursor-pointer font-semibold text-sm lg:text-base 
               ${
                 activeMenu === "about-us"
                   ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                   : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
               }
              `}
          >
            {t("aboutUs")}
          </p>
          <a
            href={hrefPackages}
            onClick={() => handleMenuClick("packages")}
            className={`block px-4 py-2 cursor-pointer font-semibold text-sm lg:text-base
               ${
                 activeMenu === "packages"
                   ? "text-primary dark:text-primary-dark hover:text-text dark:hover:text-text-dark"
                   : "text-text dark:text-text-dark hover:text-primary dark:hover:text-primary-dark"
               }
              `}
          >
            {t("packageOptions")}
          </a>
          <a
            href={hrefPartner}
            className="block px-4 py-2 text-2xl font-bold text-gray-900 dark:text-white"
          >
            <DefaultButton
              label={t("partnerWithUs")}
              textSize="text-sm lg:text-base"
              textColor="text-primary dark:text-primary-dark"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
              bg="transparent"
              hover="hover:text-white hover:dark:text-white hover:bg-cyan-600 hover:dark:bg-cyan-800 transition duration-300"
            />
          </a>
          <a
            href={hrefPackages}
            className="block px-4 py-2 text-2xl font-bold text-gray-900 dark:text-white"
          >
            <DefaultButton
              label={t("registerNow")}
              textSize="text-sm lg:text-base"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
            />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
