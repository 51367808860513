import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const ErrorModal = ({ isOpen, onClose, message }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="m-4 p-6 pb-12 bg-surface dark:bg-surface-dark rounded-3xl shadow-lg max-w-lg w-full">
        <div className="flex justify-end">
          <button
            className="top-0 right-0 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            onClick={onClose}
          >
            <XMarkIcon className="h-auto w-6" />
          </button>
        </div>
        <div className="flex items-center justify-center">
          <XCircleIcon className="h-16 w-16 text-red-500" />
        </div>
        <div className="items-center text-center mt-6">
          <h1 className="mb-2 md:mb-4 text-text dark:text-text-dark text-lg md:text-xl font-bold">
            {t("ptnrFailed")}
          </h1>
          <p className="text-text dark:text-text-dark max-h-48 md:max-h-64 overflow-y-auto">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
