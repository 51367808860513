import { createSlice } from '@reduxjs/toolkit';

// Get the initial state from localStorage or use a default value
const initialState = {
  activeMenu: localStorage.getItem('activeMenu') || '', // Retrieve from localStorage
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
      localStorage.setItem('activeMenu', action.payload); // Save to localStorage
    },
  },
});

export const { setActiveMenu } = menuSlice.actions;
export default menuSlice.reducer;