import React from "react";
import PackageCard from "./PackageCard";
import { redirectToWA } from "../../services/helperService";
import { useTranslation } from "react-i18next";

const PackageList = () => {
  const { t } = useTranslation();

  const packages = [
    {
      id: "P_INDIVIDU",
      name: t("pckgIndividu"),
      unitPrice: "Rp 150.000",
      totalPrice: "Rp 150.000",
      startingPrice: "Rp 150.000",
      features: [t("pckgIndividuItem1"), t("pckgIndividuItem2")],
      hasTotal: false,
      unit: "",
      hasUnit: false,
      paymentUrl: "https://checkout.xendit.co/od/wish-individual",
    },
    {
      id: "P_BER_5",
      name: t("pckgFive"),
      unitPrice: "Rp 120.000",
      totalPrice: "Rp 600.000",
      startingPrice: "Rp 750.000",
      features: [t("pckgFiveItem1"), t("pckgFiveItem2")],
      hasTotal: true,
      unit: "/pax",
      hasUnit: true,
      paymentUrl: "https://checkout.xendit.co/od/wish-ber-5",
    },
    {
      id: "P_BER_10",
      name: t("pckgTen"),
      unitPrice: "Rp 112.500",
      totalPrice: "Rp 1.125.000",
      startingPrice: "Rp 1.500.000",
      features: [
        t("pckgTenItem1"),
        t("pckgTenItem2"),
      ],
      hasTotal: true,
      unit: "/pax",
      hasUnit: true,
      paymentUrl: "https://checkout.xendit.co/od/wish-ber-10",
    },
    {
      id: "P_SEKOLAH",
      name: t("pckgSchool"),
      unitPrice: t("pckgGetOffer"),
      totalPrice: "",
      startingPrice: "",
      features: [
        t("pckgSchoolItem1"),
        t("pckgSchoolItem2"),
        t("pckgSchoolItem3"),
      ],
      hasTotal: false,
      unit: "",
      hasUnit: false,
      paymentUrl: "",
    },
  ];
  const onPackageClicked = (packageItem) => {
    if (packageItem?.id === "P_SEKOLAH") {
      redirectToWA();
      return;
    }
    window.location.href = packageItem.paymentUrl;
  };

  return (
    <div className="container max-w-7xl mx-auto px-4 py-36 h-1/2">
      <div className="text-center mb-14 2xl:mb-16">
        <h1 className="text-2xl md:text-4xl 2xl:text-5xl font-bold leading-tight text-text-light dark:text-text-dark">
          {t("pckgTitle1")}{" "}
          <span className="text-primary dark:text-primary-dark">
            {t("pckgTitle2")}
          </span>{" "}
          {t("pckgTitle3")}{" "}
          <span className="text-primary dark:text-primary-dark">
            {t("pckgTitle4")}
          </span>{" "}
          {t("pckgTitle5")}
        </h1>
        <p className="mt-4 xl:px-36  text-center text-sm sm:text-sm md:text-lg 2xl:text-xl text-gray-600 dark:text-gray-300">
          {t("pckgSubTitle")}
        </p>
      </div>
      {/* <div className="flex flex-col items-start justify-center space-y-4 sm:space-y-6 md:flex-row md:space-y-0 md:space-x-4"> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {packages.map((item, index) => (
          <PackageCard
            key={index}
            id={item.id}
            name={item.name}
            unitPrice={item.unitPrice}
            totalPrice={item.totalPrice}
            startingPrice={item.startingPrice}
            features={item.features}
            hasTotal={item.hasTotal}
            unit={item.unit}
            hasUnit={true}
            onPackageClick={() => {
              onPackageClicked(item);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default PackageList;
