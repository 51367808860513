import { MoonIcon, SunIcon } from "@heroicons/react/16/solid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../redux/menu/themeSlice";

function ThemeToggle({ iconColor = "text-gray-800 dark:text-gray-200" }) {
  const activeTheme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();

  const setCurrentTheme = (theme) => {
    dispatch(setTheme(theme));
  };

  useEffect(() => {
    if (activeTheme) {
      setCurrentTheme(activeTheme);
      document.documentElement.classList.toggle("dark", activeTheme === "dark");
    } else {
      setCurrentTheme("light");
      document.documentElement.classList.toggle("dark", false);
    }
    // eslint-disable-next-line
  }, [activeTheme]);

  const toggleTheme = () => {
    const newTheme = activeTheme === "light" ? "dark" : "light";
    setCurrentTheme(newTheme);
    document.documentElement.classList.toggle("dark", newTheme === "dark");
  };

  return (
    <button
      onClick={toggleTheme}
      className={`focus:outline-none p-3 rounded-full md:bg-gray-200 md:dark:bg-gray-800
        ${iconColor}
        `}
    >
      {activeTheme === "light" ? (
        <SunIcon className="w-6 h-6" />
      ) : (
        <MoonIcon className="w-6 h-6" />
      )}
    </button>
  );
}

export default ThemeToggle;
