import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasePage from "../../components/Core/Base/BasePage";
import SEO from "../../components/Core/Base/SEO";
import RegisterForm from "../../components/Register/RegisterForm";
import RegisterSuccessModal from "../../components/Register/RegisterSuccessModal";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../components/Core/Modal/ErrorModal";

function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const aboutRef = useRef(null);
  const { t } = useTranslation();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => {
    document.body.classList.remove("no-scroll");
    setIsErrorModalOpen(false);
  };

  const openSuccessModal = () => setIsSuccessModalOpen(true);
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    document.body.classList.remove("no-scroll");
    navigate(-1, { replace: true });
  };

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const goToAbout = () => {
    scrollToSection(aboutRef);
  };

  const handleBackClick = () => {
    // Check if there is a page in history before this one
    if (document.referrer) {
      // Navigate back to the previous page if it exists
      navigate(-1);
    } else {
      // Redirect to home or root if there's no page in history
      navigate("/");
    }
  };

  const onRegisterSuccess = () => {
    openSuccessModal();
  };

  const onRegisterError = (message) => {
    setErrorMessage(message);
    openErrorModal();
  };

  useEffect(() => {
    // Scroll to top of the page
    window.scrollTo(0, 0);

    if (isErrorModalOpen || isSuccessModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [navigate, isErrorModalOpen, isSuccessModalOpen]);

  return (
    <>
      <SEO
        title={t("ptnrSeoTitle")}
        description={t("ptnrSeoDescription")}
        keywords="WISH, alat test minat, minat, jurusan, pekerjaan"
        name="Daya Dimensi Indonesia"
        type="article"
        url={`${window.location.origin}${location.pathname}`}
      />
      <BasePage aboutUsOnClick={goToAbout} solidNavbar={true} hasMenu={false}>
        <div className="flex justify-center min-h-screen">
          <div className="container w-full mx-auto z-10">
            {/* Register Form */}
            <div className="lg:flex lg:justify-center h-auto w-full relative pb-10 py-32">
              {/* Back Button at Top Left */}
              <button
                onClick={handleBackClick}
                className="absolute top-24 mt-2 lg:top-28 left-4 text-text dark:text-text-dark"
              >
                <ArrowLeftIcon className="w-6 h-6" />
              </button>
              <div className="w-full md:mt-[5svh]">
                <RegisterForm
                  onErrorRegister={onRegisterError}
                  onSuccessRegister={onRegisterSuccess}
                />
              </div>
            </div>
          </div>
        </div>
        <RegisterSuccessModal
          isOpen={isSuccessModalOpen}
          onClose={closeSuccessModal}
        />
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          message={errorMessage}
        />
      </BasePage>
    </>
  );
}

export default Register;
