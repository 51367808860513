import React from "react";
import { ReactComponent as WaIcon } from "../../../assets/wa-icon.svg";

function DefaultButton({
  label,
  onClick,
  textSize = "text-lg",
  textColor = "text-white",
  bg = "bg-primary dark:bg-primary-dark",
  hover = "hover:bg-cyan-600 hover:dark:bg-cyan-800 transition duration-300",
  px = "px-4",
  py = "py-2",
  mt = "mt-0",
  border = "",
  borderColor = "",
  width = "",
  type = "",
  loading = false,
  disabled = false,
  logo = <WaIcon className="w-6 h-auto" />,
  hasLogo = false,
  logoOnRight = false,
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`font-bold rounded-full 
        ${textSize} ${px} ${py} ${mt} ${textColor} ${bg} ${hover} ${border} ${borderColor}
        ${width}
      `}
    >
      {loading ? (
        <svg className="animate-spin h-5 w-5 mr-3 my-1" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
      ) : hasLogo ? (
        logoOnRight ? (
          <div className="flex items-center justify-center">
            {label}
            <span className="ml-2">{logo}</span>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <span className="mr-2">{logo}</span>
            {label}
          </div>
        )
      ) : (
        label
      )}
    </button>
  );
}

export default DefaultButton;
