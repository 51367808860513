import React from "react";
import DefaultButton from "../Core/Button/DefaultButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WaIcon } from "../../assets/ic-users.svg";
import { useTranslation } from "react-i18next";

function Promotion() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onRegisterClicked = () => {
    navigate("/partnership");
  };

  return (
    <div className="bg-surfaceSection dark:bg-surfaceSection-dark">
      <div className="container mx-auto px-4 py-20 ">
        <section className="flex items-center justify-center">
          <div className="container text-center md:w-3/4">
            <h1 className="mb-10 text-2xl md:text-3xl 2xl:text-4xl font-bold leading-tight text-text-light dark:text-text-dark">
              {t("partnershipPromo")}
            </h1>

            <DefaultButton
              label={t("registerToBecomePartner")}
              onClick={onRegisterClicked}
              px="px-8 md:px-10"
              py="py-2"
              mt="mt-0"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
              textSize="text-base md:text-lg"
              hasLogo={true}
              logo={<WaIcon className="w-6 h-auto" />}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Promotion;
