// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../locales/en/translation.json";
import translationID from "../locales/id/translation.json";

// Translation resources
const resources = {
  id: { translation: translationID },
  en: { translation: translationEN },
};

// Set default lang
if (!localStorage.getItem("i18nextLng")) {
  localStorage.setItem("i18nextLng", "en");
}

// Init i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: { default: ["en"] },
    supportedLngs: ["id", "en"],
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
