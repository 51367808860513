import React from "react";
import DefaultButton from "../Core/Button/DefaultButton";
import { useTranslation } from "react-i18next";

const PackageCard = ({
  id,
  name,
  unitPrice,
  hasTotal = false,
  totalPrice,
  startingPrice,
  features = [],
  onPackageClick,
  unit,
  hasUnit = false,
}) => {
  const packageIdSekolah = "P_SEKOLAH";
  const { t } = useTranslation();

  return (
    <div className="max-w-sm mx-auto w-full h-[28rem] bg-surface dark:bg-surface-dark rounded-xl overflow-hidden shadow-sm border-2 dark:border-gray-600 flex flex-col">
      {/* Header */}
      <div className="bg-secondary dark:bg-secondary-dark p-4">
        <h2 className="text-text dark:text-text-dark text-basic font-bold">
          {name}
        </h2>
      </div>

      {/* Unit Price */}
      <div className="p-4">
        <span className="text-lg font-semibold text-text dark:text-text-dark">
          {unitPrice}
        </span>
        {hasUnit ? (
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>

      <hr className=" dark:border-gray-600" />

      {/* Features */}
      <div className="flex-1 mx-2 p-4 overflow-y-auto">
        <ul className="mx-2 text-base text-text dark:text-text-dark list-disc space-y-2">
          {features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
      </div>

      <div className={`p-4 ${hasTotal ? "" : "hidden"}`}>
        <h4 className="text-base font-medium text-text dark:text-text-dark mb-1">
          Total
        </h4>
        <div>
          <span className="text-xl font-semibold text-text dark:text-text-dark">
            {totalPrice}
          </span>
          <span className="ml-3 text-base line-through text-gray-400 dark:text-gray-500 mr-2">
            {startingPrice}
          </span>
        </div>
      </div>

      {/* Footer */}

      <div className="p-4">
        <DefaultButton
          label={id === packageIdSekolah ? t("pckgContact") : t("pckgSelect")}
          width="w-full"
          border={
            id === packageIdSekolah
              ? "border-2 border-primary dark:border-primary-dark"
              : ""
          }
          bg={
            id === packageIdSekolah
              ? "bg-surface dark:bg-surface-dark"
              : "bg-primary dark:bg-primary-dark"
          }
          textColor={
            id === packageIdSekolah
              ? "text-primary dark:text-primary"
              : "text-white"
          }
          hover={
            id === packageIdSekolah
              ? "hover:bg-gray-200 hover:dark:bg-gray-600 transition duration-300"
              : "hover:bg-cyan-600 hover:dark:bg-cyan-800 transition duration-300"
          }
          onClick={onPackageClick}
          hasLogo={id === packageIdSekolah}
        />
      </div>
    </div>
  );
};

export default PackageCard;
