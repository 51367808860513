import { TrashIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const FileInput = ({
  id,
  name,
  accept,
  onChange,
  onDelete,
  errorMessage = null,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [warning, setWarning] = useState("");
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const warningNonValidFile = t("ptnrFFileWrongFormat");
  const warningOverSizedFile = t("ptnrFFileOverSize");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (isValidFile(file) && isValidFileSize(file)) {
      setSelectedFile(file);
      setWarning("");
      if (onChange) {
        onChange(event);
      }
    } else {
      if (!isValidFile(file)) {
        console.log("ini");
        setWarning(warningNonValidFile);
        return;
      }

      if (!isValidFileSize(file)) {
        console.log("itu");
        setWarning(warningOverSizedFile);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (isValidFile(file)) {
      setSelectedFile(file);
      setWarning("");
      if (onChange) {
        onChange({
          target: {
            name,
            files: event.dataTransfer.files,
          },
        });
      }
    } else {
      setWarning(warningNonValidFile);
      setSelectedFile(null);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const isValidFile = (file) => {
    return file && file.type.startsWith("application/zip");
  };

  const isValidFileSize = (file) => {
    // Validate file size (1MB = 1048576 bytes)
    // if (file.size > 1048576) {

    return file.size <= 1048576;
  };

  const handleDelete = () => {
    setIsDragging(false);
    setSelectedFile(null);
    setWarning("");
    if (onDelete) {
      onDelete();
    }
  };

  useEffect(() => {
    if (errorMessage != null) {
      setWarning(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center 
      ${selectedFile ? "hidden" : ""}
      `}
      >
        <input
          type="file"
          id={id}
          name={name}
          accept={accept}
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <div
          className={`flex flex-col items-center justify-center border-2 border-dashed w-full shadow-sm  rounded-md p-6 cursor-pointer
        ${
          warning
            ? "border-red-500 "
            : isDragging
            ? "border-primary bg-primary bg-opacity-20 dark:bg-opacity-50"
            : "bg-surface dark:bg-surface-dark dark:border-gray-500"
        }
        `}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <p className="text-sm text-gray-400  text-center">
            <span className="text-primary">{t("ptnrFFilePlaceholder1")}</span>{" "}
            {t("ptnrFFilePlaceholder2")}
            <br></br>
            <span className="text-sm italic">{t("ptnrFFilePlaceholder3")}</span>
          </p>
        </div>
        {warning && <p className="text-red-500 text-sm mt-2">{warning}</p>}
      </div>

      {selectedFile ? (
        <div className="flex flex-col items-center justify-center border-2 border-dashed w-full shadow-sm  rounded-md p-6 cursor-pointer bg-surface dark:bg-surface-dark dark:border-gray-500">
          <div className="flex items-center space-x-2 justify-between w-full">
            <p className="flex-1 text-sm text-text dark:text-text-dark text-center">
              {selectedFile.name}
            </p>
            <button
              type="button"
              className="text-white rounded-full p-2 hover:bg-red-300 hover:text-white"
              onClick={handleDelete}
            >
              <TrashIcon className="w-6 h-6 text-gray-400 " />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FileInput;
