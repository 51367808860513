// src/pages/NotFound.js
import React from "react";
import BasePage from "../Base/BasePage";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const NotFound = () => {
  return (
    <BasePage solidNavbar={true} hasMenu={false}>
      <div className="flex flex-col items-center justify-center py-64 text-text dark:text-text-dark">
        <MagnifyingGlassIcon className="h-auto w-10 mb-2 text-gray-400" />
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <p className="text-xl mb-8">Halaman tidak ditemukan</p>
      </div>
    </BasePage>
  );
};

export default NotFound;
