// src/components/ComplexScrollAnimation.js
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AboutImage from "../../assets/about.png";
import DefaultButton from "../Core/Button/DefaultButton";
import { ReactComponent as ArrowIcon } from "../../assets/arrow-square-out.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setActiveMenu } from "../../redux/theme/menuSlice";

gsap.registerPlugin(ScrollTrigger);

const About = forwardRef((props, ref) => {
  const { onOpenReportPreview } = props;
  const { t } = useTranslation();
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const localRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const target = localRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          dispatch(setActiveMenu("about-us"));
        } else {
          dispatch(setActiveMenu(""));
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    gsap.fromTo(
      descriptionRef.current,
      { opacity: 0, x: -500 }, // Start with text hidden and positioned 100px to the left
      {
        opacity: 1,
        x: 0, // End with text fully visible and in its original position
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: descriptionRef.current,
          start: "top 80%", // Start when the top of the text hits 80% of the viewport
          end: "top 20%", // End when the top of the text hits 50% of the viewport
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, y: 200 }, // Start with text hidden and positioned 100px to the left
      {
        opacity: 1,
        y: 0, // End with text fully visible and in its original position
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top 95%", // Start when the top of the text hits 80% of the viewport
          end: "top 50%", // End when the top of the text hits 50% of the viewport
          scrub: true,
        },
      }
    );
  }, []);

  // Expose specific methods to the parent component
  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
    focus: () => {
      if (localRef.current) {
        localRef.current.focus();
      }
    },
  }));

  return (
    <div ref={localRef}>
      <section className="h-auto md:h-svh py-16 md:py-36 md:pb-32 bg-surfaceSection dark:bg-surfaceSection-dark flex items-center justify-center">
        <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 py-10 items-center md:w-4/5">
          <div
            ref={descriptionRef}
            className="p-4 text-text dark:text-text-dark"
          >
            <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl text-primary dark:text-primary-dark font-bold">
              {t("whatIsWish")}
            </h1>
            <p className="mt-6 text-lg sm:text-lg md:text-2xl lg:text-2xl">
              {t("aboutWish1")} <strong>{t("aboutWish2")}</strong>.
            </p>
            <p className="mt-6 text-lg sm:text-lg md:text-2xl lg:text-2xl">
              {t("aboutWish3")} <strong>{t("aboutWish4")}</strong>{" "}
              {t("aboutWish5")}
            </p>
            <DefaultButton
              label={t("seeReportPreview")}
              onClick={onOpenReportPreview}
              px="px-8 md:px-10"
              py="py-2"
              mt="mt-8"
              border="border-2"
              borderColor="border-primary dark:border-primary-dark"
              textSize="text-base md:text-lg"
              hasLogo={true}
              logoOnRight={true}
              logo={<ArrowIcon className="w-6 h-auto" />}
            />
          </div>
          <div ref={imageRef} className="p-4 text-text dark:text-text-dark">
            <div className="flex justify-center items-center h-auto w-auto">
              <img src={AboutImage} alt="Example" className="w-full" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default About;
