import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { remark } from "remark";
import remarkGfm from "remark-gfm";
import remarkHtml from "remark-html";
import remarkParse from "remark-parse";
import { visit } from "unist-util-visit";

function TncRenderer() {
  const { i18n } = useTranslation();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const pathFileId = "/tnc-id.md";
  const pathFileEn = "/tnc-en.md";

  // Headings
  const customHeadingPlugin = () => (tree) => {
    visit(tree, "heading", (node) => {
      // For example, add a custom class to all headings
      const level = node.depth; // heading level (1 for h1, 2 for h2, etc.)
      if (node.depth === 1) {
        node.data = {
          hProperties: {
            className: [
              "custom-heading-markdown",
              `heading-level-${level}`,
              "text-center",
              "text-2xl",
            ],
          },
        };
      } else {
        node.data = {
          hProperties: {
            className: ["custom-heading-markdown", `heading-level-${level}`],
          },
        };
      }
    });
  };

  // Strong element
  const customStrongPlugin = () => (tree) => {
    /// strong
    visit(tree, "strong", (node) => {
      if (node?.children?.length > 0) {
        node?.children.forEach((child) => {
          node.type = "html";
          node.value = `<span class="font-bold">${child.value}</span>`;
        });
      } else {
        node.type = "html";
        node.value = `<span class="font-bold">${node.value}</span>`;
      }
    });
  };

  // Emphasis element
  const customEmphasisPlugin = () => (tree) => {
    visit(tree, "emphasis", (node) => {
      if (node?.children?.length > 0) {
        node?.children.forEach((child) => {
          if (child?.type === "strong" && child?.children?.length > 0) {
            child?.children.forEach((child2) => {
              node.type = "html";
              node.value = `<span class="italic font-bold">${child2?.value}</span>`;
            });
          } else {
            node.type = "html";
            node.value = `<span class="italic">${child?.value}</span>`;
          }
        });
      } else {
        node.type = "html";
        node.value = `<span class="italic">${node?.value}</span>`;
      }
    });
  };

  // Table elements
  const customTablePlugin = () => (tree) => {
    visit(tree, "table", (node) => {
      node.data = {
        hProperties: {
          className: "custom-table-markdown",
        },
      };
    });

    visit(tree, "tableRow", (node, index) => {
      node.data = {
        hProperties: {
          className:
            index === 0
              ? "custom-table-header-row-markdown"
              : "custom-table-row-markdown",
        },
      };
    });

    visit(tree, "tableCell", (node, index, parent) => {
      node.data = {
        hProperties: {
          className: "custom-table-cell-markdown",
        },
      };
    });
  };

  // Unordered list elements
  const customUnorderedListPlugin = () => (tree) => {
    visit(tree, "list", (node) => {
      const isOrdered = node.ordered;
      if (!isOrdered) {
        node.children.forEach((listNode) => {
          listNode.data = {
            hProperties: {
              className: ["custom_unordered-list-markdown"],
            },
          };
        });
      }
    });
  };

  // Ordered list elements
  const customOrderedListPlugin = () => (tree) => {
    visit(tree, "list", (node) => {
      const isOrdered = node.ordered;
      if (isOrdered) {
        node.children.forEach((listNode) => {
          listNode.data = {
            hProperties: {
              className: ["custom_ordered-list-markdown"],
            },
          };
        });
      }
    });
  };

  // Note blockquote element
  const customNotePlugin = () => (tree) => {
    /// blockquote
    visit(tree, "blockquote", (node) => {
      // [note]
      if (
        node.children.length > 0 &&
        node.children[0]?.children[0]?.value?.startsWith("[note]:")
      ) {
        const keyword = "[note]:";
        let contents = [];

        node.children?.forEach((child) => {
          if (child?.type === "paragraph") {
            if (child.children?.length > 0) {
              let childContent = `
                <p class="content-block">
                  ${child.children
                    ?.map((item) =>
                      item?.value?.replace(keyword, "")?.replace(/\n/g, "<br/>")
                    )
                    .join("")}
                </p>
              `;
              contents.push(childContent);
            }
          }
        });

        node.type = "html";
        node.value = `
          <div class="note-block-markdown">
            <span class="note-asterix">*</span>${contents
              .map((item) => item)
              .join("")}
          </div>
        `;
      }
    });
  };

  useEffect(() => {
    const processMarkdown = async (lng) => {
      try {
        setLoading(true);
        // await new Promise((resolve) => setTimeout(resolve, 3000));
        let path = pathFileId; // default lng id
        if (lng === "en") path = pathFileEn;
        const response = await fetch(path); // path relative to 'public'
        const text = await response.text();
        const processedContent = await remark()
          .use(remarkParse)
          .use(remarkHtml, { sanitize: false })
          .use(remarkGfm)
          .use(customHeadingPlugin)
          .use(customTablePlugin)
          .use(customStrongPlugin)
          .use(customEmphasisPlugin)
          .use(customUnorderedListPlugin)
          .use(customOrderedListPlugin)
          .use(customNotePlugin)
          .process(text);
        setContent(processedContent.toString());
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    processMarkdown(i18n.language);

    // Listen for language changes
    const handleLanguageChange = (lng) => {
      processMarkdown(lng);
    };

    i18n.on("languageChanged", handleLanguageChange);

    // Cleanup the listener on component unmount
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const LoadingBar = () => {
    return (
      <div className=" mx-auto w-1/2 h-2 bg-gray-200 dark:bg-gray-600 rounded overflow-hidden mb-16">
        <div className="h-full bg-primary animate-loading-bar rounded"></div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 lg:px-0 md:w-2/3 xl:w-1/2 py-36">
      {loading ? (
        <LoadingBar />
      ) : (
        <div
          className="custom-prose-markdown text-text dark:text-text-dark"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
}

export default TncRenderer;
