import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ExpansionTile from "../Core/Expansion/ExpansionTile";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Faq = forwardRef(({ defaultTab = "about-wish" }, ref) => {
  const textRef = useRef(null);
  const localRef = useRef(null);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { t } = useTranslation();

  // Expose specific methods to the parent component
  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
    focus: () => {
      if (localRef.current) {
        localRef.current.focus();
      }
    },
  }));

  useEffect(() => {}, []);

  return (
    <div ref={localRef}>
      <div className="h-auto py-16  flex items-center justify-center">
        <div className="container mx-auto px-4 py-8">
          <section ref={textRef} className="flex items-center justify-center">
            <div className="container text-center">
              <h1 className="mb-10 text-2xl sm:text-2xl md:text-4xl lg:text-5xl font-extrabold text-text-light dark:text-text-dark">
                {t("faqTitle1")}{" "}
                <span className="text-primary dark:text-primary-dark">
                  {t("faqTitle2")}
                </span>{" "}
                {t("faqTitle3")}
              </h1>
              <div className="w-full mx-auto">
                {/* Tabs */}
                <div className="flex justify-center mb-4">
                  <button
                    className={`p-4 text-sm md:text-base font-medium ${
                      activeTab === "about-wish"
                        ? "border-b-4 border-primary text-text dark:text-text-dark"
                        : "text-gray-500 dark:text-gray-300"
                    }`}
                    onClick={() => setActiveTab("about-wish")}
                  >
                    {t("aboutWish")}
                  </button>
                  <button
                    className={`p-4 text-sm md:text-base font-medium ${
                      activeTab === "purchase-wish"
                        ? "border-b-4 border-primary text-text dark:text-text-dark"
                        : "text-gray-500 dark:text-gray-300"
                    }`}
                    onClick={() => setActiveTab("purchase-wish")}
                  >
                    {t("pnp")}
                  </button>
                  <button
                    className={`p-4 text-sm md:text-base font-medium ${
                      activeTab === "technical-wish"
                        ? "border-b-4 border-primary text-text dark:text-text-dark"
                        : "text-gray-500 dark:text-gray-300"
                    }`}
                    onClick={() => setActiveTab("technical-wish")}
                  >
                    {t("tech")}
                  </button>
                </div>

                {/* Content */}
                <div className="pb-4">
                  {activeTab === "about-wish" && (
                    <div className="m-auto w-auto md:w-3/4">
                      <ExpansionTile
                        title={t("aboutWishQ1")}
                        value={t("aboutWishA1")}
                      />
                      <ExpansionTile
                        title={t("aboutWishQ2")}
                        value=<div>
                          <ul>
                            <li>
                              <strong>{t("aboutWishA2S1Title")}</strong>
                              <br></br>
                              {t("aboutWishA2S1Content")}
                            </li>
                            <br></br>
                            <li>
                              <strong>{t("aboutWishA2S2Title")}</strong>
                              <br></br>
                              {t("aboutWishA2S2Content")}
                            </li>
                          </ul>
                        </div>
                      />
                    </div>
                  )}
                  {activeTab === "purchase-wish" && (
                    <div className="m-auto w-auto md:w-3/4">
                      <ExpansionTile title={t("pnpQ1")} value={t("pnpA1")} />
                      <ExpansionTile
                        title={t("pnpQ2")}
                        value=<div>
                          <h2 className="mb-4">{t("pnpA2Title")}</h2>
                          <ol className="list-decimal list-outside ml-4 space-y-2 [&_li]:marker:font-semibold [&_li]:marker:text-gray-600 [&_li]:marker:dark:text-gray-400">
                            <li>{t("pnpA2Item1")}</li>
                            <li>{t("pnpA2Item2")}</li>
                            <li>{t("pnpA2Item3")}</li>
                            <li>{t("pnpA2Item4")}</li>
                            <li>{t("pnpA2Item5")}</li>
                            <li>{t("pnpA2Item6")}</li>
                          </ol>
                        </div>
                      />
                      <ExpansionTile
                        title={t("pnpQ3")}
                        value=<div>
                          <h2 className="mb-4">{t("pnpA3Title")}</h2>
                          <ol className="list-decimal list-outside ml-4 space-y-2 [&_li]:marker:font-semibold [&_li]:marker:text-gray-600 [&_li]:marker:dark:text-gray-400">
                            <li>{t("pnpA3Item1")}</li>
                            <li>{t("pnpA3Item2")}</li>
                            <li>{t("pnpA3Item3")}</li>
                            <li>{t("pnpA3Item4")}</li>
                            <li>{t("pnpA3Item5")}</li>
                            <li>{t("pnpA3Item6")}</li>
                          </ol>
                        </div>
                      />
                      <ExpansionTile title={t("pnpQ4")} value={t("pnpA4")} />
                      <ExpansionTile title={t("pnpQ5")} value={t("pnpA5")} />
                    </div>
                  )}
                  {activeTab === "technical-wish" && (
                    <div className="m-auto w-auto md:w-3/4">
                      <ExpansionTile title={t("techQ1")} value={t("techA1")} />
                      <ExpansionTile title={t("techQ2")} value={t("techA2")} />
                      <ExpansionTile title={t("techQ3")} value={t("techA3")} />
                      <ExpansionTile title={t("techQ4")} value={t("techA4")} />
                      <ExpansionTile
                        title={t("techQ5")}
                        value=<div>
                          <ol className="list-decimal list-outside ml-4 space-y-2 [&_li]:marker:font-semibold [&_li]:marker:text-gray-600 [&_li]:marker:dark:text-gray-400">
                            <li>{t("techA5Item1")}</li>
                            <li>{t("techA5Item2")}</li>
                            <li>{t("techA5Item3")}</li>
                            <li>{t("techA5Item4")}</li>
                          </ol>
                        </div>
                      />
                      <ExpansionTile title={t("techQ6")} value={t("techA6")} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
});

export default Faq;
